import Navbar from "./components/navbar";
import Home from "./components/home";
import About from "./components/about";
import Skills from "./components/skills";
import Work from "./components/work";
import Contact from "./components/contact";


function App() {
  return (
    <>
    <Navbar />
    <Home />
    <About />
    <Skills />
    <Work />
    <Contact />
    </>
   
  );
}

export default App;
